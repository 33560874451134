import $ from 'jquery'

$('#menu-box').click(function () {
  $('body').toggleClass('body--hidden')
  $('#header').toggleClass('header--open')
})

$('#menu--about').click(function () {
  $('#header').addClass('header--about')
  $('#menu-box').css('display', 'none')
  $('#menu-box--back').css('display', 'block')
})

$('#menu-box--back').click(function () {
  $('#header').removeClass('header--about')
  $('#menu-box').css('display', 'block')
  $('#menu-box--back').css('display', 'none')
})

$('.modal-click').click(function () {
  $('body').toggleClass('body--hidden')
  $('#modal').toggleClass('modal--open')
})

$('#modal--close').click(function () {
  $('#modal').removeClass('modal--open')
  $('body').removeClass('body--hidden')
})

Array.from(document.querySelectorAll('.footer__select')).forEach(element => {
  element.onclick = function () {
    element.classList.toggle('footer__select--active')
  }
})

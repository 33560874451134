import $ from 'jquery'

$('.category-slider').slick({
  dots: false,
  infinite: true,
  variableWidth: false,
  slidesToShow: 4,
  slidesToScroll: 3,
  speed: 300,
  nextArrow: '.category-slider--right',
  prevArrow: '.category-slider--left',

  responsive: [
    {
      breakpoint: 1300,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
        speed: 300,
      },
    },
  ],
})

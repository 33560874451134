import $ from 'jquery'

$('.ref-slider').slick({
  dots: false,
  infinite: true,
  variableWidth: false,
  slidesToShow: 1.65,
  slidesToScroll: 1,
  speed: 300,
  nextArrow: '.slider__arrow-box--right',
  prevArrow: '.slider__arrow-box--left',

  responsive: [
    {
      breakpoint: 1300,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
        speed: 300,
      },
    },
  ],
})

const AMPLITUDE = -40

function applyParallaxEffect(wrapperId) {
  const el = document.getElementById(wrapperId)
  if (!el) return
  const target = el.querySelector('.img-move')
  const mouse = { x: 0, y: 0, moved: false }
  let rect = el.getBoundingClientRect()

  el.addEventListener('mousemove', function (e) {
    mouse.moved = true
    mouse.x = e.clientX - rect.left
    mouse.y = e.clientY - rect.top
    tick()
  })

  function tick() {
    window.requestAnimationFrame(function () {
      if (mouse.moved) {
        transform()
        mouse.moved = false
        tick()
      }
    })
  }

  function transform() {
    const x = ((mouse.x - rect.width / 2) / rect.width) * AMPLITUDE
    const y = ((mouse.y - rect.height / 2) / rect.height) * AMPLITUDE
    target.style.transform = `translate(${x}px, ${y}px) scale(1.1)`
  }

  const listener = function () {
    rect = el.getBoundingClientRect()
  }

  window.addEventListener('resize', listener)
  window.addEventListener('scroll', listener)
}

applyParallaxEffect('container-move')
applyParallaxEffect('container-move-2')

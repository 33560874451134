import $ from 'jquery'

$(document).on('click', '#filter-btn', function () {
  $('#filter-box').removeClass('hidden')
})

$(document).on('click', '#filter-btn-close', function () {
  $('#filter-box').addClass('hidden')
})

let isEnterExpanded = false
$(document).on('click', '#select-enter-door', function () {
  isEnterExpanded = !isEnterExpanded
  $(this).find('svg').toggleClass('rotate-90')
  $('#enter-door-nav').toggleClass('hidden')
})

let initCalled = false
function init() {
  if (initCalled) return
  initCalled = true

  window.addEventListener('popstate', function (event) {
    const queryString = new URLSearchParams(window.location.search).toString()
    const url = `/vchodove-dvere/?${queryString}`
    jQueryRefresh(url)
  })
}

window.onFormChange = function (event) {
  init()
  const queryString = new URLSearchParams(new FormData(event.currentTarget)).toString()
  const url = `/vchodove-dvere/?${queryString}`
  const isVisible = !$('#filter-box').hasClass('hidden')
  jQueryRefresh(url, function () {
    if (isVisible) {
      $('#filter-box').removeClass('hidden')
    }
  })
  window.history.pushState(undefined, '', url)
  event.preventDefault()
}

function jQueryRefresh(url, callback) {
  $.get(url).then(text => {
    const html = $(text)

    $('#page').replaceWith(html.filter('#page'))

    if (isEnterExpanded) {
      $('#select-enter-door svg').addClass('rotate-90')
      $('#enter-door-nav').removeClass('hidden')
    }

    document.title = html.filter('title').text()
    callback && callback()
  })
}
